import React from "react";
import { Page } from "../../components/Page";
import { Title } from "../../components/Title";
import { Content } from "../../components/Content";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const PrinciplesPage = () => (
  <Page language="en" page="Principles">
    <Row>
      <Col>
        <Title>Warning principles</Title>

        <Content as="ul">
          <li>
            The warning message shall be sent during the most dangerous road
            conditions, when keeping on one’s feet requires special caution and
            care or even aid devices.
          </li>
          <li>
            As desired, people may also be warned by night, as necessary, but it
            cannot be promised for sure. The warning will be sent by the duty
            officer, whose working hours by night depend on the overall demand
            of the winter maintenance.
          </li>
          <li>
            The warned person can choose, whether the warning message on the
            night slipperiness will be sent right away, or at 6 am, 7 am, 8 am
            or not until 9 am. At weekends, the warning will be sent an hour
            later.
          </li>
          <li>
            If the dangerous slippery road condition is continued on the
            following day, it will be warned again in the morning.
          </li>
          <li>End of the slippery conditions will not be informed on.</li>
        </Content>

        <Content>
          To prevent "inflation" of the warnings, they are sent with the aim
          that over half of the population in the area feels that the road
          conditions are very slippery.
        </Content>

        <Content>
          <b>
            One must thus be cautious although not received a warning. The
            service does not change in any way the slippery prevention
            responsibilities of the city or the pedestrian’s own responsibility.
          </b>
        </Content>
      </Col>
    </Row>
  </Page>
);

export default PrinciplesPage;
